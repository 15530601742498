var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-3 mt-5"},[_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-2"},[_vm._v(" Solicitud de Transporte / Retiro ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb}})],1),_c('v-row',{staticClass:"mt-1 mb-5",attrs:{"align-content":"start","align-md":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('filter-project',{attrs:{"label":"Proyecto","projects":_vm.projects.items
            ? _vm.projects.items.filter(function (item) { return item.warehouse; })
            : [],"getProjectsFunction":_vm.getProjects},on:{"cleanProject":_vm.cleanCurrentProject,"inputProject":function($event){_vm.updateCurrentProject($event);
          _vm.getRequestsDeliveryData(_vm.filters.page);}}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"clearable":"","placeholder":"Buscar:"},on:{"input":function($event){return _vm.getRequestsDeliveryData(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-btn',{staticClass:"float-right",attrs:{"disabled":!_vm.requestsSelected.length || _vm.filters.project_code,"color":"primary text-white","small":""},on:{"click":_vm.openModalDeliveryRequests}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-truck-outline ")]),_vm._v(" Despachar ")],1)],1)],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-column flex-sm-row mb-1"},[_c('p',{staticClass:"text-info font-weight-bold text-right"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Selecciona las solicitudes a despachar ")],1),_c('h5',{staticClass:"my-0"},[_vm._v(" Seleccionados: "+_vm._s(_vm.requestsSelected.length)+" ")])]),_c('v-data-table',{staticClass:"px-1 elevation-3",attrs:{"headers":_vm.tableHeaders,"items":_vm.wrappedRequestsTransfer,"items-per-page":10,"page":_vm.filters.page,"hide-default-footer":"","loading":_vm.loadingRequestsDelivery,"loading-text":"Cargando solicitudes de traslado","show-select":"","item-key":"id","no-data-text":"Sin solicitudes de traslado"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)}},scopedSlots:_vm._u([{key:"item.project",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-left my-0"},[_vm._v(" "+_vm._s(item.project.code)+" ")])]}},{key:"item.sap_code",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-left my-0"},[_vm._v(" "+_vm._s(item.sap_code)+" ")])]}},{key:"item.received_type",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-center my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.received_type ? item.received_type : "-")+" ")])]}},{key:"item.stage_code",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.stage_code ? item.stage_code : "-")+" ")])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"11x"}},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")])]}},{key:"item.received_at",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"12px"}},[(item.received_at)?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.moment(item.received_at).format("DD/MM/YYYY HH:mm [hrs]"))+" ")]):_c('v-chip',{attrs:{"color":"warning","small":""}},[_c('small',[_vm._v(" Sin Definir ")])])],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.openModalMaterialsRequestTransfer(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-view-list-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver materiales de la solicitud")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"icon":"","disabled":!item.received_at,"color":item.received_at ? 'primary' : 'warning',"x-small":""},on:{"click":function($event){return _vm.openModalDetailsDispatch(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-truck ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalles del despacho "+_vm._s(!item.received_at ? "(Sin Despachar)" : ""))])])],1)]}}]),model:{value:(_vm.requestsSelected),callback:function ($$v) {_vm.requestsSelected=$$v},expression:"requestsSelected"}}),_c('div',{staticClass:"my-3 text-center"},[_c('v-pagination',{attrs:{"total-visible":8,"length":_vm.requestsDelivery.number_of_pages
          ? _vm.requestsDelivery.number_of_pages
          : 1},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('modal-delivery-requests',{attrs:{"modalAction":_vm.dialogDeliveryRequess,"closeModalDeliveryRequests":_vm.closeModalDeliveryRequests,"requestsToDelivery":_vm.requestsSelected,"materialsToDelivery":_vm.materialsToDispatch},on:{"requestsDispatches":function($event){_vm.getRequestsDeliveryData(1);
      _vm.closeModalMaterialsRequestTransfer();}}}),_c('modal-materials-request-transfer',{attrs:{"modalAction":_vm.dialogMaterialsRequestTransfer,"actionDispatch":false,"currentRequestTransfer":_vm.currentRequestTransfer,"closeModalMaterialsRequestTransfer":_vm.closeModalMaterialsRequestTransfer},on:{"openModalDetailsDispatch":_vm.openModalDetailsDispatch,"openModalDeliveryMaterials":_vm.setMaterialsToDispatchAndOpenModalDeliveryRequests}}),_c('modal-details-dispatch',{attrs:{"modalAction":_vm.dialogDetailsDispatch,"closeModalDetailsDispatch":_vm.closeModalDetailsDispatch,"currentItemDispatch":_vm.currentItemDetailsDispatch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }